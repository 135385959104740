jQuery(function($) {
    $('.dipi_testimonial.et_pb_module').each(function(index, value) {
        let $this = $(this);
        let main_element = value.querySelector('.dipi-testimonial-main')
        if (!main_element) {
            return
        }
        var data = value.querySelector('.dipi-testimonial-main').dataset;
        let selector = "." + $this.attr('class').split(' ').join('.') + " .swiper-container";

        var navigation = "on" === data.navigation && {
            nextEl: ".dipi_testimonial .dipi-button-next-" + data.ordernumber,
            prevEl: ".dipi_testimonial .dipi-button-prev-" + data.ordernumber
        };
        var dynamicbullets = ('on' == data.dynamicbullets) ? true : false;
        var pagination = "on" === data.pagination && {
            el: ".dipi_testimonial .dipi-pagination-" + data.ordernumber,
            clickable: true,
            dynamicBullets: dynamicbullets,
            dynamicMainBullets: 1
        };

        var cfe = {
            rotate: Number(parseInt(data.rotate)),
            stretch: 5,
            depth: 100,
            modifier: 1,
            slideShadows: true,
        };

        var mySwiper = new Swiper(selector, {
            slidesPerView: Number(data.columnsphone),
            spaceBetween: Number(data.spacebetween_phone),
            speed: Number(data.speed),
            loop: "on" === data.loop,
            autoplay: "on" === data.autoplay && {
                delay: data.autoplayspeed
            },
            effect: data.effect,
            coverflowEffect: "coverflow" === data.effect ? cfe : null,
            navigation: navigation,
            pagination: pagination,
            centeredSlides: "on" === data.centered,
            slideClass: "dipi-testimonial-item",
            wrapperClass: "dipi-testimonial-wrapper",
            setWrapperSize: true,
            observer: true,
            observeParents: true,
            breakpoints: {

                768: {
                    slidesPerView: Number(data.columnstablet),
                    spaceBetween: Number(data.spacebetween_tablet) > 0 ? Number(data.spacebetween_tablet) : Number(0),
                },

                981: {
                    slidesPerView: Number(data.columnsdesktop),
                    spaceBetween: Number(data.spacebetween) > 0 ? Number(data.spacebetween) : Number(0),
                }

            }
        });

        if ('on' === data.pauseonhover && 'on' === data.autoplay) {

            $this.find('.swiper-container').on('mouseenter', function(e) {
                mySwiper.autoplay.stop();
            });

            $this.find('.swiper-container').on('mouseleave', function(e) {
                mySwiper.autoplay.start();
            });
        }
    });

    $('.dipi-open-popup-link').magnificPopup({
        type: 'inline',
        mainClass: 'dipi-mfp-with-zoom',
        removalDelay: 300,
        closeOnContentClick: true,
        midClick: true,
        callbacks: {
            open: function() {
                $('body').addClass('dipi-testimonial-review-popup-open');
                $('html').css('overflow', 'auto').css('margin-right', '0');
            },
            close: function() {
                $('body').removeClass('dipi-testimonial-review-popup-open');
            }
        }
    });
});